<template>
  <div class="sensor-page x-background-color x-box-shadow">
    <x-table
      new-style
      :no-data-text="CA('sensor_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    >
    </x-table>

    <add-modal
      v-model="modal.show"
      :title="modal.title"
      :baseSence="baseSence"
      :sensorType="sensorTypeOptions"
      :row="row"
      @add-success="addSuccess"
    ></add-modal>

    <sync v-model="syncShow" :guid="guid" @sync-success="syncSuccess"></sync>
  </div>
</template>

<script>
import addModal from "./addTwo";
import sync from "./sync.vue";
export default {
  name: "",
  components: {
    addModal,
    sync,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "监测点名称",
            key: "groupName",
          },
          {
            title: "监测名称",
            key: "name",
          },
          {
            title: "通道类型",
            key: "channelTypeName",
          },
          {
            title: "场景类型/监测类别",
            width: 160,
            render: (h, { row }) => {
              return (
                <span>{row.sensorTypeName + "/" + row.sensorCategoryName}</span>
              );
            },
          },
          {
            title: "罗拉设备",
            key: "unicomName",
          },
          {
            title: "监测值范围",
            render: (h, { row }) => {
              return (
                <span>{row.categoryMinRange + "~" + row.categoryMaxRange}</span>
              );
            },
          },
          {
            title: "量程范围",
            render: (h, { row }) => {
              let obj = JSON.parse(row.computeJson || "{}");
              return row.channelType == 1 ? (
                <span>
                  {(obj.minRange || "") + " ~ " + (obj.maxRange || "")}
                </span>
              ) : (
                <span>--</span>
              );
            },
          },
          {
            title: "微安值范围",
            render: (h, { row }) => {
              let obj = JSON.parse(row.computeJson || "{}");
              return row.channelType == 1 ? (
                <span>
                  {(obj.minRangeMicro || "") +
                    " ~ " +
                    (obj.maxRangeMicro || "")}
                </span>
              ) : (
                <span>--</span>
              );
            },
          },
          {
            title: "百分比(%)",
            render: (h, { row }) => {
              let obj = JSON.parse(row.computeJson || "{}");
              return row.channelType == 4 ? (
                <span>{obj.percentage || ""}</span>
              ) : (
                <span>--</span>
              );
            },
          },
          {
            title: "读取位数",
            render: (h, { row }) => {
              let obj = JSON.parse(row.computeJson || "{}");
              return row.channelType == 4 ? (
                <span>{obj.num || ""}</span>
              ) : (
                <span>--</span>
              );
            },
          },
          {
            title: "状态",
            key: "statusName",
          },

          {
            title: "操作",
            minWidth: 100,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("sensor_update") &&
                    row.companyNo == localStorage.getItem("companyNo") && (
                      <Icon
                        style="cursor:pointer;color:#00b477;font-size:16px;margin-right:10px"
                        type="ios-brush"
                        title="编辑"
                        onClick={() => this.edit(row)}
                      ></Icon>
                    )}
                  {this.CA("sensor_num") &&  (
                    <Icon
                      style="cursor:pointer;color:#08dddd;font-size:16px;margin-right:10px"
                      type="ios-pulse"
                      title="设置自定义数值范围"
                      onClick={() => this.monitorRange(row)}
                    ></Icon>
                  )}
                  {localStorage.userId == "1" && (
                    <span
                      class="iconfont"
                      style="cursor:pointer;color:#08dddd;font-size:16px;margin-right:10px"
                      title="数据同步"
                      onClick={() => this.syncDevice(row.guid)}
                    >
                      &#xe66b;
                    </span>
                  )}
                  {this.CA("sensor_delete") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <Icon
                        style="cursor:pointer;color:#e4393c;font-size:16px;"
                        type="md-trash"
                        title="删除"
                      ></Icon>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },

      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },

      baseSence: [],
      selBaseSence: [],

      sensorTypeOptions: [],
      selSensorType: [],

      search_data: {
        groupName: "",
        name: "",
      },

      modal: {
        show: false,
        title: "",
      },
      row: null,
      guid: "",
      syncShow: false,
      channelList: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增监测",
            ca: "sensor_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "监测点名称",
              component: "input",
              field: "groupName",
              defaultValue: "",
            },
            {
              conditionName: "监测名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "通道类型",
              component: "select",
              field: "channelType",
              defaultValue: "",
              data: this.channelList,
              parameterField: "num",
              showField: "name",
              clearable: true,
            },
            {
              conditionName: "监测类型",
              component: "cascader",
              field: "sensor",
              defaultValue: [],
              data: this.sensorTypeOptions,
              isLoadData: true,
              loadData: this.getSensorCategoryList,
            },
          ],
        },

        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },

    search(data) {
      // this.search_data = data;
      let obj = {
        sensorType: data.sensor[0],
        sensorCategory: data.sensor[1],
        ...data,
      };
      this.search_data = obj;
      this.getList();
    },

    reset() {
      this.search_data = {
        groupName: "",
        name: "",
      };
      this.selSensorType = [];
      this.selBaseSence = [];
      this.getList();
    },

    add() {
      this.row = null;
      this.modal = {
        show: true,
        title: "新增监测",
      };
    },
    addSuccess() {
      this.modal.show = false;
      this.getList();
    },
    edit(row) {
      this.row = row;
      this.modal = {
        show: true,
        title: "编辑监测",
      };
    },

    del(id) {
      this.$post(this.$api.BUSSDEVICESENSOR.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },

    syncDevice(guid) {
      this.guid = guid;
      this.syncShow = true;
    },
    syncSuccess() {
      this.syncShow = false;
    },

    monitorRange(data) {
      let na = data.name;
      let gu = data.guid;
      this.$router.push({
        // path: `/main/warningManage?n=${na}&g=${gu}`,
        name: "warningManage",
      });
    },

    getList() {
      if (!this.CA("sensor_check")) return;
      this.table.loading = true;
      this.$post(this.$api.BUSSDEVICESENSOR.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    //基地场景
    getArea() {
      this.$post(this.$api.PRODUCTION_AREA.FARMSCENE).then((res) => {
        let str = JSON.stringify(res)
          .replace(/id/g, "value")
          .replace(/name/g, "label")
          .replace(/sceneList/g, "children");
        let area = JSON.parse(str);
        this.baseSence = area;
      });
    },
    // getArea() {
    //   this.$post(this.$api.PRODUCTION_AREA.LIST, {
    //     pageNo: 1,
    //     pageSize: 999,
    //   }).then((res) => {
    //     let str = JSON.stringify(res.list)
    //       .replace(/sceneId/g, "id")
    //       .replace(/sceneName/g, "name")
    //       .replace(/farmId/g, "id")
    //       .replace(/farmName/g, "name");
    //     this.baseSence = CU.formatTreeData(
    //       JSON.parse(str),
    //       "id",
    //       "name",
    //       "childList"
    //     );
    //   });
    // },

    getSensorTypeList() {
      this.$post(this.$api.DICT.SYS_LIST, {
        name: "传感器场景类型",
      }).then((res) => {
        let sensorTypeOptions = [];
        res.forEach((item, index) => {
          sensorTypeOptions.push({
            value: item.num,
            label: item.name,
            children: [],
            loading: false,
          });
        });
        this.sensorTypeOptions = sensorTypeOptions;
      });
    },

    // 获取监测类别
    getSensorCategoryList(item, cb) {
      item.loading = true;
      let name = "";
      switch (item.value) {
        case 1:
          name = "种植业传感器分类";
          break;
        case 2:
          name = "养殖业传感器分类";
          break;
        case 3:
          name = "水产业传感器分类";
          break;
      }
      this.$post(this.$api.DICT.SYS_LIST, {
        name,
      })
        .then((resp) => {
          let sensorCategoryOptions = [];
          for (let val of resp) {
            sensorCategoryOptions.push({
              value: val.num,
              label: val.name,
            });
          }
          item.children = sensorCategoryOptions;
          item.loading = false;
          cb();
        })
        .catch((e) => {
          item.loading = false;
          cb();
        });
    },
    //获取设备厂商
    getChannelType() {
      this.$post(this.$api.DICT.SYS_LIST, {
        name: "设备厂商",
      }).then((resp) => {
        this.channelList = resp;
      });
    },
  },
  mounted() {
    this.getArea();
    this.getSensorTypeList();
    this.getList();
    this.getChannelType();
  },
};
</script>

<style lang="less" scoped>
.sensor-page {
  width: 100%;
  height: 100%;
  .table-header {
    padding: 16px;
    display: flex;
    flex-wrap: nowrap;
    .search-pane {
      flex-shrink: 1;
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      span {
        display: flex;
        padding-right: 16px;
      }
    }
  }
  .footer {
    padding: 16px;
    text-align: right;
  }
}
</style>